import React, { Fragment, useMemo } from "react";
import { LeadLenderApps } from "~/model/leads";
import { LeadLenderApp, PrequalOffersTable } from "./PrequalOffersTable";
import { OfferVehicleCard } from "./OfferVehicleCard";

import { useLeadState } from "@leads/context";
import { ProductCtaCard } from "~/components/ProductCta/Card";
import { isProductEnabled } from "@utils/products";
import { ProductType } from "@api/products";
import { useAuth } from "@api/auth";
import { GridItem } from "@leads/Grid";
import Axis from "../Axis";

export const LenderConnect = () => {
	const {
		state: { lead, loading, print },
	} = useLeadState();
	const { selectedCompany } = useAuth();

	const isPrequalToolEnabled = isProductEnabled(
		selectedCompany,
		ProductType.LenderTool,
	);

	const content = useMemo(() => {
		// if (!isPrequalToolEnabled) {
		// 	return <ProductCtaCard topic="LENDER_TOOL" />;
		// }

		const lenderApps: LeadLenderApps = lead?.lenderApps.nodes || [];
		return lenderApps.map((app: LeadLenderApp, i) => (
			<Fragment key={i}>
				<GridItem sm={12}>
					<Axis />
				</GridItem>
				{/* <GridItem sm={12}>
					<OfferVehicleCard lenderApp={app} />
				</GridItem> */}
				{/* <GridItem sm={12}>
					<PrequalOffersTable
						lenderApp={app}
						lead={lead}
						loading={loading}
					/>
				</GridItem> */}
			</Fragment>
		));
	}, [lead, loading, isPrequalToolEnabled]);

	const hideOnPrint = print && !lead?.lenderApps.nodes?.length;
	if (hideOnPrint) {
		return null;
	}

	return <>{content}</>;
};
